import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import router from './router';
import AppService from "@/services/AppService";
import { SnackbarService } from "vue3-snackbar";
import VueCookies from 'vue3-cookies';


loadFonts()
const app = createApp(App)
app.$service = (fn, ...args) => {
    if (
        typeof AppService[fn] !== "undefined" &&
        typeof AppService[fn] == "function"
    ) {
        return AppService[fn](...args);
    }
};

app.use(router)
    .use(vuetify)
    .use(SnackbarService)
    .use(VueCookies)
    .mount('#app');
